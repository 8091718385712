<template>
    <comp-table title="导购机（导购员）管理" ref="comp_table" edit-key="uuid" :columns="columns" :table-api="tableApi" :delete-api="v => '/gateway/apps/o2o/api/terminal/deleteTerminal?code=' + v.uuid" :formComponent="formComponent" :operate="getTableOperate">
        <template v-slot:search="data">
            <Input v-model.trim="data.search.terminalName" placeholder="导购机名称" style="width: 160px"></Input>
            <Select v-model="data.search.terminalGroupId" filterable style="width:200px" transfer placeholder="群组">
                <Option v-for="item in groups" :value="item.id" :key="'groups-' + item.id">{{ item.groupName }}</Option>
            </Select>
        </template>
    </comp-table>
</template>

<script>
import Route from "../../../communitymobilization/entity/Route"
import CompTable from "../../../jointly/components/comp-table.vue"
import formComponent from "./form.vue"
import qrcode from "qrcodejs2"

export default {
    components: { CompTable },

    data() {
        return {
            formComponent: formComponent,

            groups: null,

            tableApi: "/gateway/apps/o2o/api/terminal/getTerminalByPage?oemCode=" + parent.vue.oemInfo.oemCode,

            columns: [
                {
                    title: "UUID",
                    key: "uuid",
                    minWidth: 300,
                    align: "center",
                },
                {
                    title: "终端名称",
                    key: "terminalName",
                    minWidth: 300,
                    align: "center",
                },
                {
                    title: "绑定群组",
                    key: "terminalGroupName",
                    minWidth: 300,
                    align: "center",
                },
                {
                    title: "联系方法",
                    minWidth: 300,
                    align: "center",
                    render: (h, params) => {
                        return h("p", {}, [params.row.contactperson, params.row.phone].join(" - ") || "-")
                    },
                },
                {
                    title: "运行状态",
                    minWidth: 300,
                    align: "center",
                    render: (h, params) => {
                        return h(
                            "p",
                            {
                                attrs: {
                                    style: `color:${params.row.heartbeatStatus === 1 ? "#ff0000" : ""}`,
                                },
                            },
                            ["正常", "异常"][params.row.heartbeatStatus] || "-"
                        )
                    },
                },
                {
                    title: "启禁用",
                    minWidth: 200,
                    align: "center",
                    render: (h, params) => {
                        return h("p", {}, params.row.idisplay === 1 ? "启用" : "禁用")
                    },
                },
            ],
        }
    },

    mounted() {
        this.getGroup()
    },

    methods: {
        /**
         * 获取群组
         */
        getGroup() {
            this.$get("/gateway/apps/o2o/api/terminal/goods/getTerminalGroupOptions", {
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
            }).then(res => {
                if (res.code == 200) {
                    this.groups = res.dataList
                }
            })
        },

        /**
         * 获取表格操作菜单
         */
        getTableOperate(h, params) {
            return [
                h(
                    "DropdownItem",
                    {
                        nativeOn: {
                            click: () => {
                                let el = document.createElement("div")
                                // 生成二维码
                                new qrcode(el, "https://supplier.shop.liefengtech.com/#/apps/suppliershop/pages/guide/guide?uuid=" + params.row.uuid)
                                // 获取画布
                                let canvas = el.getElementsByTagName("canvas")[0]
                                let a = document.createElement("a")
                                // 转换数据
                                a.href = canvas.toDataURL("image/png")
                                a.download = "二维码"
                                a.click()
                            },
                        },
                        style: {
                            textAlign: "center",
                        },
                    },
                    "生成二维码"
                ),
                h(
                    "DropdownItem",
                    {
                        nativeOn: {
                            click: () => {
                                // 跳转至运行状态页面
                                Route.jump(`/terminal_status?uuid=${params.row.uuid}&terminalGroupId=${params.row.terminalGroupId}`)
                            },
                        },
                        style: {
                            textAlign: "center",
                        },
                    },
                    "运行状态"
                ),
                h(
                    "DropdownItem",
                    {
                        nativeOn: {
                            click: () => {
                                const d = params.row.idisplay

                                this.$post("/gateway/apps/o2o/api/terminal/enableTerminal", {
                                    terminalUUId: params.row.uuid,
                                    idisplay: d === 1 ? 0 : 1,
                                }).then(res => {
                                    if (res.code == 200) {
                                        this.$Message.success((d === 1 ? "禁用" : "启用") + "成功")
                                        // 取反
                                        params.row.idisplay = d === 1 ? 0 : 1
                                    }
                                })
                            },
                        },
                        style: {
                            textAlign: "center",
                        },
                    },
                    params.row.idisplay === 1 ? "禁用" : "启用"
                ),
            ]
        },
    },
}
</script>
<style lang="less"></style>
